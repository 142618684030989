import './ComponentBase.css';
import './Input.css';

const Input = (props) => {
    return (
        <div key={props.name + '-input'} className={props.name + ' input-field'}>
            <label htmlFor={props.name} className="text-07">{props.label} {props.required ? (<span className='field-required-mark'>*</span>) : ''}:</label>
            <div className="field-description text-03">{props.description && props.description.length > 0 ? props.description : ''}</div>
            <input id={props.name} onChange={props.change} type={props.type} value={props.value} name={props.name} min={props.min} max={props.max} step={props.step} required={props.required}/> 
        </div>
    )
}
export default Input