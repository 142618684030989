import './ComponentBase.css';
import './Select.css'

const Select = (props) => {
    return (
        <div key={props.name + '-input'} className={props.name + ' input-field'}>
            <label htmlFor={props.name} className="text-07">{props.label} {props.required ? (<span className='field-required-mark'>*</span>) : ''}:</label>
            <div className="field-description text-03">{props.description && props.description.length > 0 ? props.description : ''}</div>
            <select className={"input-field-select" + (props.multiple ? " multiple-select" : "")} value={props.value} id={props.name} name={props.name} onChange={props.change} multiple={props.multiple} required={props.required}>
                {props.options.map(val => {
                    return (<option key={val.value} value={val.value}>{val.name}</option>)
                })}
            </select>
        </div>
    )
}
export default Select