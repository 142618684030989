import './ComponentBase.css';
import './Submit.css'

const Submit = (props) => {
    return (
        <div key={props.name + '-input'} className={props.name + ' input-field'}>
            <input className="btn btn-success" id={props.name} type="submit" value={props.value} onClick={props.click} disabled={props.disabled}></input>
        </div>
    )
}
export default Submit