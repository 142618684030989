import './ComponentBase.css';
import './Text.css'

const Text = (props) => {
    return (
        <div key={props.name + '-input'} className={props.name + ' input-field'}>
            <label htmlFor={props.name} className="text-07">{props.label} {props.required ? (<span className='field-required-mark'>*</span>) : ''}</label>
            {props.description && props.description.length > 0 ? (<span className="field-description text-03">{props.description}</span>) : ''}
            {
                props.required ? 
                <textarea id={props.name} name={props.name} onChange={props.change} value={props.value} className="text-03" required></textarea> 
                : <textarea id={props.name} name={props.name} onChange={props.change} value={props.value} className="text-03"></textarea>
            }
        </div>
    )
}
export default Text