import './ComponentBase.css';
import './Loader.css'
import loader from './loading.gif'

const Loader = (props) => {
    return (
        <div key={props.name + '-loader'} className='loader'>
            <span className="text-07">{props.loaderText}</span>
            <img alt="loading" src={loader}></img>
        </div>
    )
}
export default Loader