import Input from './components/Input'
import File from './components/File'
import Text from './components/Text'
import Select from './components/Select'
import Submit from './components/Submit'
import Loader from './components/Loader'
import { Suspense, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import config from './config'
import Modal from 'react-modal'
import FormTooltips from './Tooltips'

import './App.css';
import logo from './imgs/opinion.svg'
import de_img from './imgs/de.png'
import en_img from './imgs/en.png'
import pl_img from './imgs/pl.png'
import check_img from './imgs/check-solid.svg'


function App() {
  // utils
  const { i18n, t } = useTranslation();

  // app states
  const [isModalActive, setModalActive] = useState(false)
  const [isLoadingActive, setLoadingActive] = useState(false)
  const [isNoFileCheck, setNoFileCheck] = useState(false)
  const [isSuccessfulyRegistered, setSuccessfulyRegistered] = useState(false)
  const [isTooManyRequests, setTooManyRequests] = useState(false)
  const [isFormBeingSubmitted, setFormBeingSubmitted] = useState(false)
  const [manualOrderEntry, setManualOrderEntry] = useState(false)
  const [manualDetailsEntry, setManualDetailsEntry] = useState(false)

  const [clientHash, setClientHash] = useState("")
  // const [clientPass, setClientPass] = useState("")
  const [requestedOrderId, setReqOrderId] = useState("-1")
  // const [modalError, setModalError] = useState('')
  const [orderHistory, setOrderHistory] = useState({})
  const [orderList, setOrderList] = useState([])
  const [jobList, setJobList] = useState([])
  const [complaintNo, setComplaintNo] = useState(null)

  const [errorText, setErrorText] = useState("")

  // form fields
  const [reasons, setReasons] = useState([])
  const [firstName, setFirstName] = useState('')
  const [secondName, setSecondName] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [orderId, setOrderId] = useState("")
  const [reason, setReason] = useState("")
  const [reasonLabel, setReasonLabel] = useState("")
  const [file, setFile] = useState("")

  const [positions, setPositions] = useState([{
    "positionNo": 1,
    "positionJobNo": "1",
    "positionQty": 1,
  }])


  const [description, setDescription] = useState(``)
  const [expectation, setExpectations] = useState(``)

  
  const submit = (e) => {
    e.preventDefault()
    // console.log(e.target.reportValidity())
    const form = document.querySelector('form')
    console.log('submit clicked', e)
    console.log('formdata', new FormData(form))
    if (form.reportValidity()) {
      setFormBeingSubmitted(true)
      // form.submit()
      const url = `${config.apiService[process.env.NODE_ENV]}/register`
      console.log(url)
      fetch(url, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: new FormData(form),
      })
      .then(response => response.json())
      .then(json => {
        // todo: check for errors and if no redirect to landing page
        setFormBeingSubmitted(false)
        console.log("submit res", json)
        if (typeof json.error !== 'undefined') {
          if (json.error.msg) {
            setErrorText(t(json.error.msg))
          } else {
            setErrorText(t(json.error.code)) 
          }
          setTimeout(() => setErrorText(""), 5000)

          if (json.error.msg === 'error-too-many-requests') {
            setTooManyRequests(true)
            setTimeout(() => setTooManyRequests(false), 30000)
          }
        } else {
          setSuccessfulyRegistered(true)
          if (json.complaintNo.length > 0) {
            setComplaintNo(json.complaintNo[1])
          }
        }
      })
      .catch(error => {
        setFormBeingSubmitted(false)
        setErrorText(t(error))
        setTimeout(() => setErrorText(""), 5000)
        console.log('err', error)
      })
    }
    return
  }

  const setValue = (name, value) => {
    switch(name) {
      case 'first-name': setFirstName(value); break
      case 'second-name' : setSecondName(value); break
      case 'email-address' : setEmailAddress(value); break
      case 'order-id' : setOrderId(value); break
      case 'reason' :
        setReason(value);
        // setReasonLabel(value)
        const reasonLabels = reasons.filter(val => {
          return val.value === value
        })
        if (reasonLabels.length > 0) {
          setReasonLabel(reasonLabels[0].name)
        }
        break
      case 'description' : setDescription(value); break
      case 'file' :
        setFile(value);
        console.log('setFile', value)
        break
      case 'expectations' : setExpectations(value); break
      default: console.log('def \'' + name + '\'')
    }
  }

  const selectOrderIDChanged = (e) => {
    setReqOrderId(e);
    const ordersHistoryIdList = Object.keys(orderHistory)
    if (ordersHistoryIdList.length > 0 && ordersHistoryIdList.indexOf(e) !== -1) {
      setJobList(orderHistory[e]?.jobs?.map(v => {
        return {
          name: `${t('job_id')} ${v.jobId} - ${v.name} - ${v.format}cm - ${v.qty} ${t('qty')}`,
          value: `${v.jobId}`,
        }
      }))
    }
  }


  const checkPassword = (e) => {
    if (clientHash.length > 0) {
      setLoadingActive(true)
      const url = `${config.apiService[process.env.NODE_ENV]}/auth`
      console.log(url)
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({"clientHash": clientHash}),
      })
      .then(response => response.json())
      .then(data => {
        setLoadingActive(false)
        console.log('authResult', data)
        if (data.authStatus === 'successful') {
          setFirstName(data.clientInfo.firstName);
          setSecondName(data.clientInfo.secondName);
          setEmailAddress(data.clientInfo.email);
          setManualDetailsEntry(false)
          if (requestedOrderId !== null) {
            let selectOrderList = Object.keys(data.clientInfo.idList).map((id, key) => {
              return {name: `${id} - ${data.clientInfo.idList[id].reference}`, value: id}
            })
            selectOrderList = [{name: t('select-one'), value: ''}, ...selectOrderList]

            setOrderHistory(data.clientInfo.idList)
            setOrderList(selectOrderList)
          }
          setModalActive(false)
        } else {
          // diplay auth error
          setManualDetailsEntry(true)
          if (typeof data.errorMsg !== 'undefined') {
            setErrorText(t(data.errorMsg))
            if (data.errorMsg === 'error-too-many-requests') {
              // setModalError(t(data.errorMsg))
              // setTimeout(() => setModalError(""), 5000)
              setTooManyRequests(true)
            }
          }
          setTimeout(() => setErrorText(""), 5000)
        }
      })
      .catch(error => {
        console.log('err', error)
        setLoadingActive(false)
      })
    }
  }

  const swapLang = (e, lng) => {
    checkPassword(e)
    if (typeof lng !== 'undefined') {
      i18n.changeLanguage(lng)
      .then(() => {
        let selectOrderList = orderList.map(v => {
          if (v.value === '') {
            v.name = t('select-one')
          }
          return v
        })
        setOrderList(selectOrderList)
      });
      fetch(`/reasons.${lng}.json`)
      .then(response => response.json())
      .then(reasonJson => {
        setReasons(reasonJson)
        setReasonLabel(reasonJson[0].name)
      })
      .catch(err => {
        // setReasons([])
        console.log(err)
      })
    }
  }

  useEffect(() =>{
    const handleBeforeUnload = (event) => {
      // Perform actions before the component unloads
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    console.log('use effect clientHash', clientHash)
    if (clientHash.length > 0) {
      console.log('use effect clientHash checkpassword', clientHash)
      checkPassword()
    }
  }, [clientHash])

  useEffect(() => {
    if (Object.keys(orderHistory).length > 0 && Object.keys(orderHistory).indexOf(requestedOrderId) !== -1) {
      setReqOrderId(requestedOrderId);
      setJobList(orderHistory[requestedOrderId]?.jobs?.map(v => {
        return {
          name: `${t('job_id')} ${v.jobId} - ${v.name} - ${v.format}cm - ${v.qty} ${t('qty')}`,
          value: `${v.jobId}`,
        }
      }))
    }
  }, [orderHistory, requestedOrderId, t])


  useEffect(() => {
    // for edi auth and order fetch
    // todo promise this
    const searchParams = new URLSearchParams(document.location.search)
    if (searchParams.has('h')) {
      // setModalActive(true)
      setClientHash(searchParams.get('h'))
      if (searchParams.has('o')) {
        setReqOrderId(searchParams.get('o'))
      }
    } else {
      setManualDetailsEntry(true)
      if (searchParams.has('o')) {
        setOrderId(searchParams.get('o'))
      }
    }
    if (searchParams.has('l')) {
      // setAppLang(searchParams.get('l'))
      i18n.changeLanguage(searchParams.get('l'))
      .then(() => {
        fetch(`/reasons.${i18n.resolvedLanguage}.json`)
        .then(response => response.json())
        .then(reasonJson => {
          setReasons(reasonJson)
          setReasonLabel(reasonJson[0].name)
        })
        .catch(err => {
          setReasons([])
          setReasonLabel('')
          console.log(err)
        })
      })
      .catch(err => {
        console.log('lng change err', err)
      });
    } else {
      fetch(`/reasons.${i18n.resolvedLanguage}.json`)
      .then(response => response.json())
      .then(reasonJson => {
        setReasons(reasonJson)
        setReasonLabel(reasonJson[0].name)

      })
      .catch(err => {
        setReasons([])
        setReasonLabel('')

        console.log(err)
      })
    }

    return () => {
    }
  }, [i18n])

  const customStyles = {
    "content": {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: "50px"
    }
  };

  const addPosition = (e) => {
    e.preventDefault()
    let lastId = 1
    if (positions.length > 0) {
      lastId = positions[positions.length - 1].positionNo + 1
    }
    
    setPositions([...positions, {
        "positionNo": lastId,
        "positionJobNo": "1",
        "positionQty": 1,
      }])
  }

  const autoJobChanged = (e, positionNo) => {
    let newPositions = positions.map((v, key) => {
      if (v.positionNo === positionNo) {
        return {
            "positionNo": positionNo,
            "positionJobNo": e.target.value,
            "positionQty": 1,
          }
      } else {
        return v
      }
    })

    setPositions(newPositions)
  }

  const positionQtyChanged = (e, positionNo) => {
    let newPositions = positions.map((v, key) => {
      if (v.positionNo === positionNo) {
        return {
            "positionNo": positionNo,
            "positionJobNo": v.positionJobNo,
            "positionQty": e.target.value,
          }
      } else {
        return v
      }
    })

    setPositions(newPositions)
  }
  const remPosition = (e, id) => {
    e.preventDefault()
    setPositions(positions.filter(val => {
      return val.positionNo !== id
    }))
  }

  const renderNoAttachmentsInfo = () => {
    return (
      <div className="text-03">
        {t("no-file-attachments-info-txt")}
      </div>
    )
  }

  const singleManualPositionEntry = (v) => {
    return (
      <div className="position-row" key={`position-manrow-${v.positionNo}`} >
        <a className="auto-position-select-fg2" data-tooltip-id="my-tooltip" data-tooltip-html={FormTooltips.positionNoTooltip()} data-tooltip-delay-show={1000}>
          <Input name={`job-id[${v.positionNo}]`} label={t("job_id")} required={true} type='number' min="1" step="1" ></Input>  
        </a>
        <Input name={`job-qty[${v.positionNo}]`} label={t("job_qty")} required={true} type='number' min="1" step="1" ></Input>
        <button href="#" className="btn btn-warning rem-row-btn" onClick={(e) => { remPosition(e, v.positionNo) }} >{t("rem-man-position")}</button>
      </div>
    )
  }

  const renderComplaintDetailForm = () => {
    return (
      <div className="complaints-detail">
        <Text name='description' label={t("description")} change={(e) => {setValue(e.target.name, e.target.value)}} value={description} required={true}></Text>
        <div className="file-input-div">
          {
            isNoFileCheck ? renderNoAttachmentsInfo() : (
              <File name='file' label={t("file")} change={(e) => { setValue(e.target.name, e.target.files)} } value={file} required={true}></File>
            )
          }
          <a data-tooltip-id="my-tooltip" data-tooltip-html={FormTooltips.noAttachmentsTooltip()} data-tooltip-delay-show={1000}>
            <label>
              <input value={isNoFileCheck} onChange={() => {setNoFileCheck(!isNoFileCheck)}} name="no-attachments" type="checkbox"></input>
              <span>{t("no-attachment-checkbox")}*</span>
            </label>
          </a>
        </div>
        <Text name='expectations' label={t("expectations")} change={(e) => {setValue(e.target.name, e.target.value)}} value={expectation} required={true}></Text>
      </div>
    )
  }

  const manualOrderForm = () => {
    return (
      <div className="complaint-info-manual">
        <a data-tooltip-id="my-tooltip" data-tooltip-html={FormTooltips.orderIdTooltip()} data-tooltip-delay-show={1000}>
          <Input name='order-id' label={t("order_id")} change={(e) => {setValue(e.target.name, e.target.value)}} value={orderId} required={true} type='text'></Input>
        </a>
        <Select name='reason' label={t("reason")} change={(e) => {setValue(e.target.name, e.target.value)}} value={reason} options={reasons} required={true}></Select>
        <input type="hidden" name='reason-label' value={reasonLabel} />
        <div className='positions'>
          {
            positions.map((val, key) => { return singleManualPositionEntry(val, key) })
          }
        </div>
        <button className='add-row-btn btn btn-secondary' href="#" onClick={(e) => { addPosition(e) }}>{t("add-man-position")}</button>
         { renderComplaintDetailForm() }
      </div>
    )
  }

  const singleAutoPositionEntry = (v) => {
    return (
      <div key={`position-autorow-${v.positionNo}`} className="position-row">
        <a className="auto-position-select-fg2" data-tooltip-id="my-tooltip" data-tooltip-html={FormTooltips.positionNoTooltip()} data-tooltip-delay-show={1000}>
          <Select name={`job-id[${v.positionNo}]`} options={jobList} label={t("job_id")} required={true} change={(e) => { autoJobChanged(e, v.positionNo)}}></Select>
        </a>
        <Input name={`job-qty[${v.positionNo}]`} label={t("job_qty")} value={v.positionQty} change={(e) => {positionQtyChanged(e, v.positionNo)}} required={true} type='number' step="1" min="1" max={ Number(orderHistory[requestedOrderId]?.jobs[v.positionJobNo-1]?.qty)?.toString() }></Input>
        {positions.length > 0 && (<button href="#" className="btn btn-warning rem-row-btn" onClick={(e) => { remPosition(e, v.positionNo) }}>{t("rem-man-position")}</button>)}
      </div>
    )
  }

  const autoOrderForm = () => {
    return (
      <Suspense fallback={<Loader loaderText={t('loader-text')}/>}>
        <div className="complaint-info-auto">
          <div className="order-id-select">
            <div className={'order-id-autoselect input-field'}>
              <label htmlFor={'order-id-autoselect'} className="text-07">
                {t("order_id")} 
                <span className='field-required-mark'>*</span>
              </label>
              <label className="manual-entry-label" data-tooltip-id="my-tooltip" data-tooltip-html={FormTooltips.manualEntryOnAutoTooltip()} data-tooltip-delay-show={1000}>
                <input onChange={() => setManualOrderEntry(!manualOrderEntry)} className="btn manul-entry-input" type="checkbox" checked={manualOrderEntry}></input>
                <span>{t('manual_entry_order')}</span>
              </label>
              { !manualOrderEntry ? (
                <select className="input-field-select" value={requestedOrderId} id={'order-id-autoselect'} name={'order-id-autoselect'} onChange={(e) => {selectOrderIDChanged(e.target.value)}} data-tooltip-id="my-tooltip" data-tooltip-html={FormTooltips.orderIdTooltip()} required={true} data-tooltip-delay-show={1000}>
                  {orderList.map(val => {
                    return (<option key={val.value} value={val.value}>{val.name}</option>)
                  })}
                </select>
              ) : (
                <input className="input-field-select" id="order-id" type="text" name="order-id" required={true} onChange={(e) => {setOrderId(e.target.value)}} value={orderId} data-tooltip-id="my-tooltip" data-tooltip-html={FormTooltips.orderIdTooltip()} data-tooltip-delay-show={1000}></input>
              )}
            </div>
          </div>
          <Select name='reason' label={t("reason")} change={(e) => {setValue(e.target.name, e.target.value)}} value={reason} options={reasons} required={true}>
          </Select>
          <input type="hidden" name='reason-label' value={reasonLabel} />
          <div className='positions'>
            {
              positions.map(val => { return !manualOrderEntry ? singleAutoPositionEntry(val) : singleManualPositionEntry(val)})
            }
            <button className='add-row-btn btn btn-secondary' href="#" onClick={(e) => { addPosition(e)}} disabled={jobList.length < 2}>{t("add-man-position")}</button>
          </div>
          { renderComplaintDetailForm() }
        </div>
      </Suspense>
    )
  }

  const renderForm = () => {
    return (
      <div>
        <form encType="multipart/form-data" action={`${config.apiService[process.env.NODE_ENV]}/register`} method="POST">
          <div className="form-content">
            <header className="form-header">
              <p>{t("title")}</p>
            </header>
            <div>
              <h3 className="text-01">{t("your_information")}</h3>
              <div className="client-info">
                <Input name='first-name' description={t("first_name_description")} label={t("first_name")} change={(e) => {setValue(e.target.name, e.target.value)}} value={firstName} required={true} type='text'></Input>
                <Input name='second-name' label={t("second_name")} change={(e) => {setValue(e.target.name, e.target.value)}} value={secondName} required={true} type='text'></Input>
                <Input name='email-address' label={t("email_address")} change={(e) => {setValue(e.target.name, e.target.value)}} value={emailAddress} required={true} type='text'></Input>
              </div>
            </div>
            <div>
              <h3 className="text-01">{t("compl_details")}</h3>
                { manualDetailsEntry ? manualOrderForm() : autoOrderForm() }
            </div>
            <Submit name="submit-form" value={t("submit")} click={e => submit(e)} disabled={isFormBeingSubmitted}></Submit>
            <div className="errorText">{errorText.length > 0 ? errorText : ""}</div>
          </div>
        </form>
        {
          // arry of tooltips id to render
          FormTooltips.renderAllTooltips([
            "orderIdTooltip",
            "positionNoTooltip",
            "noAttachmentsTooltip",
            "manualEntryOnAutoTooltip"
          ])
        }
      </div>
    )
  }

  const renderSuccess = () => {
    return (
      <div>
        <div className="summary-success text-07">
          <div className="summary-header">
            <img src={check_img} alt="" className="h3-icon m-2" />
            <h3 className="summary-sent">{t('complaint-registered')}: {complaintNo}</h3>
			    </div>
			    <p>{t('complaint-summary-text')}</p>
		    </div>
      </div>
    )
  }

  return (
    <Suspense fallback={<Loader loaderText={t('loader-text')}/>}>
      <div>
        <nav className="nav-container text-03">
          <div>
            <img className="form-logo" src={logo} alt="logo"></img>
          </div>
          <div>
            <button className="btn-lang-ico" href="#" onClick={(e) => swapLang(e, 'de')}><img src={de_img} alt="Deutsch" className="icon m-2"></img></button>
            <button className="btn-lang-ico" href="#" onClick={(e) => swapLang(e, 'en')}><img src={en_img} alt="English" className="icon m-2"></img></button>
            <button className="btn-lang-ico" href="#" onClick={(e) => swapLang(e, 'pl')}><img src={pl_img} alt="Polski" className="icon m-2"></img></button>
          </div>
        </nav>
        <div className="main-container">
        { isLoadingActive ? 
          <Modal appElement={document.getElementById('root') || undefined} isOpen={isModalActive} style={customStyles}><Loader name='dataLoader' loaderText={t('loader-text')}/></Modal> : 
          (
            !isSuccessfulyRegistered ? renderForm() : renderSuccess()
          )
        }
        { isTooManyRequests &&
          <Modal appElement={document.getElementById('root') || undefined} isOpen={isTooManyRequests} style={customStyles}><Loader name='tooManyRequests' loaderText={t('error-too-many-requests')}/>
          </Modal>
        }
        </div>
      </div>
    </Suspense>
  );
}

export default App;
