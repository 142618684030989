import { renderToStaticMarkup } from 'react-dom/server';
import {Tooltip} from 'react-tooltip'

import orderTooltipImg1_en from './imgs/orderTooltipImg1_en.png'
import orderTooltipImg1_pl from './imgs/orderTooltipImg1_pl.png'
import orderTooltipImg1_de from './imgs/orderTooltipImg1_de.png'

import positionTooltipImg1_en from './imgs/positionTooltipImg1_en.png'
import positionTooltipImg1_pl from './imgs/positionTooltipImg1_pl.png'
import positionTooltipImg1_de from './imgs/positionTooltipImg1_de.png'


import './Tooltips.css'
import { Translation } from 'react-i18next';

const FormTooltips = {
    orderIdTooltip : () => {
        return renderToStaticMarkup(
            <div>
                <span className="text-03">
                    <Translation>{
                        t => {
                            return t('tooltip-order-id')
                        }
                      }
                    </Translation>
                </span>
                <br/>
                <Translation>{
                    (t, { i18n }) => {
                        switch (i18n.resolvedLanguage) {
                            case 'en': return <img src={ orderTooltipImg1_en } alt="order id tooltip1"></img>
                            case 'pl': return <img src={ orderTooltipImg1_pl } alt="order id tooltip1"></img>
                            case 'de': return <img src={ orderTooltipImg1_de } alt="order id tooltip1"></img>
                            default: return <img src={ orderTooltipImg1_en } alt="order id tooltip1"></img>
                        }
                    }
                  }
                </Translation>
            </div>
        )
    },

    positionNoTooltip : () => {
        return renderToStaticMarkup(
            <div>
                <span className="text-03">
                    <Translation>{
                        t => {
                            return t('tooltip-position-id')
                        }
                      }
                    </Translation>
                </span>
                <br/>
                <Translation>{
                    (t, { i18n }) => {
                        switch (i18n.resolvedLanguage) {
                            case 'en': return <img src={ positionTooltipImg1_en } alt="position tooltip1"></img>
                            case 'pl': return <img src={ positionTooltipImg1_pl } alt="position tooltip1"></img>
                            case 'de': return <img src={ positionTooltipImg1_de } alt="position tooltip1"></img>
                            default: return <img src={ positionTooltipImg1_en } alt="position tooltip1"></img>
                        }
                    }
                  }
                </Translation>
            </div>
        )
    },

    noAttachmentsTooltip : () => {
        return renderToStaticMarkup(
            <div>
                <p className="text-03">
                    <Translation>{
                        t => {
                            return t('no-file-attachments-info-txt')
                        }
                      }
                    </Translation>
                </p>
            </div>
        )
    },

    manualEntryOnAutoTooltip : () => {
        return renderToStaticMarkup(
            <div>
                <p className="text-03">
                    <Translation>{
                        t => {
                            return t('manual-order-position-entry')
                        }
                    }
                    </Translation>
                </p>
            </div>
        )
    },

    renderAllTooltips: (enabledTooltips) => {
        return (
            <div id="tooltips-renders">
                {
                    enabledTooltips.map(v => {
                        return (
                            <div
                                key={v}
                                className="item"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={FormTooltips[v]()}
                            ></div>
                        )
                    })
                }
                <Tooltip id="my-tooltip" />
            </div>
        )
    }
}

export default FormTooltips